import React from 'react';

const ErpActionTwo =()=> {
    return(
        <section className="erp_call_action_area">
            <div className="container">
                <div className="erp_action_content text-center">
                    <img src={require("../img/erp-home/rocket.png")} alt=""/>
                    <h3>Experience a smarter way to close deals</h3>
                    <p>With All-in-one<span>Business</span>Manager</p>
                    <a href="/#" className="er_btn er_btn_two">Get Started Free</a>
                </div>
            </div>
        </section>
    )
}
export default ErpActionTwo;
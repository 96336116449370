import React from "react";

const Privacidad = () => {
  let styles = {
    title: {
      textAlign: "center",
    },
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <br />
            <h3 style={styles.title}>
              AVISO DE PRIVACIDAD <br />
              PARA LA PROTECCIÓN DE DATOS PERSONALES
            </h3>
            <br />
            <p>
              En términos de lo previsto en la Ley Federal de Protección de
              Datos Personales en Posesión de los Particulares, la aportación
              que hagas de tus datos Personales a la empresa responsable
              Integropay Mexico SAPI de CV. constituye la aceptación de estos
              Términos y Condiciones:
            </p>
            <p>
              1.- El presente Aviso tiene por objeto la protección de tus datos
              personales, mediante su tratamiento legítimo, controlado e
              informado, a efecto de garantizar su privacidad, así como tu
              derecho a la autodeterminación informativa.
            </p>
            <p>
              2.- Conforme al artículo 3, fracción V, de la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares, se
              entienden los siguientes conceptos:
              <ul style={styles.list}>
                <li>
                  Aviso de Privacidad: Documento físico, electrónico o en
                  cualquier otro formato generado por el responsable que es
                  puesto a disposición del titular, previo al tratamiento de sus
                  datos personales, de conformidad con el artículo 15 de la Ley
                  Federal de Protección de Datos Personales en Posesión de los
                  Particulares.
                </li>
                <li>
                  Bases de datos: El conjunto ordenado de datos personales
                  referentes a una persona identificada o identificable.
                </li>
                <li>
                  Datos Personales: Cualquier información concerniente a una
                  persona física identificada o identificable.
                </li>
                <li>
                  Datos personales sensibles: Aquellos datos personales que
                  afecten a la esfera más íntima de su titular, o cuya
                  utilización indebida pueda dar origen a discriminación o
                  conlleve un riesgo grave para éste. En particular, se
                  consideran sensibles aquellos que puedan revelar aspectos como
                  origen racial o étnico, estado de salud presente y futuro,
                  información genética, creencias religiosas, filosóficas y
                  morales, afiliación sindical, opiniones políticas, preferencia
                  sexual.
                </li>
                <li>
                  Responsable: Persona física o moral de carácter privado que
                  decide sobre el tratamiento de datos personales.
                </li>
                <li>
                  Transferencia: Toda comunicación de datos realizada a persona
                  distinta del responsable o encargado del tratamiento.
                </li>
                <li>
                  Tratamiento: La obtención, uso, divulgación o almacenamiento
                  de datos personales, por cualquier medio. El uso abarca
                  cualquier acción de acceso, manejo, aprovechamiento,
                  transferencia o disposición de datos personales.
                </li>
              </ul>
            </p>
            <p>
              3.- Conforme al artículo 16 de la Ley Federal de Protección de
              Datos Personales en Posesión de los Particulares, establecen los
              siguientes derechos y obligaciones conforme a la siguiente
              información:
            </p>
            <p>
              PRIMERA: LA IDENTIDAD Y DOMICILIO DEL RESPONSABLE. El presente
              aviso de privacidad tiene por objeto regular el tratamiento de los
              datos personales que sean puestos a disposición de la empresa
              RESPONSABLE denominada Integropay Mexico SAPI de CV, quien es el
              responsable del uso y protección de sus datos personales.
            </p>

            <p>
              SEGUNDA: LAS FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES. La
              RESPONSABLE podrá recabar de usted los datos financieros y
              patrimoniales que resulten necesarios y/o convenientes con la
              finalidad de cumplir con las obligaciones de la Sociedad derivadas
              de la relación jurídica que existe entre la Sociedad y usted, por
              lo que, por virtud del presente usted desde ahora otorga a la
              Sociedad su consentimiento expreso para tal efecto.
            </p>

            <p>
              TERCERA: LAS OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN
              DE LOS DATOS. La RESPONSABLE puede llegar a transmitir su
              información a sus empresas subsidiarias, afiliadas o vinculadas
              que coadyuvan con la finalidad de cumplir con las obligaciones de
              la Sociedad derivadas de la relación jurídica que existe entre la
              Sociedad y usted, por lo que, por virtud del presente usted desde
              ahora otorga a la Sociedad su consentimiento expreso para tal
              efecto, en el entendido que dichos datos financieros y
              patrimoniales serán tratados con absoluta discreción y cuidado
              respecto de toda la información privada concerniente a su persona
              que usted nos proporcione, ya sea directa o indirectamente,
              verbalmente o a través de medios impresos, electrónicos,
              digitales, ópticos, sonoros, visuales y/o mediante cualquier otro
              medio, los cuales serán utilizados en forma general y conforme
              sean necesarios para proveer los servicios y/o productos que usted
              nos solicite de tiempo en tiempo, así como para informarle sobre
              los cambios en los mismos, evaluar la calidad de dichos productos
              y/o servicios, para hacerle llegar información acerca de nuestros
              productos y/o servicios y para mantener una comunicación efectiva
              con usted.
            </p>

            <p>
              {` 
                    CUARTA: LOS MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN. De conformidad con lo dispuesto en esta Ley Federal de Protección de Datos Personales en Posesión de los Particulares, para ejercer los Derechos de Acceso, Rectificación, Cancelación u Oposición de datos personales (en lo consiguiente ARCO), el Titular o su representante debe presentar una solicitud de acceso, rectificación, cancelación u oposición con la información y documentación siguiente:
                    I) Nombre del Titular y domicilio completo u otro medio para comunicarle la respuesta a su solicitud;
                    II) Los documentos que acrediten su identidad (copia simple en formato impreso o electrónico de su credencial de elector, cédula profesional con fotografía, pasaporte o FM-2 o FM-3 o Forma Migratoria vigente) o, en su caso, la representación legal del Titular (copia simple en formato impreso o electrónico de la carta poder simple con firma autógrafa del Titular, el representante y sus correspondientes identificaciones oficiales – (credencial de elector, cédula profesional con fotografía, pasaporte, FM-2 o FM- 3 o Forma Migratoria vigente);
                    III) La descripción clara y precisa de los Datos Personales respecto de los que busca ejercer alguno de los Derechos ARCO, salvo en el caso del derecho de Acceso, adjuntando, en su caso, en formato electrónico los documentos que brinden soporte a su petición.
                    Tratándose de una solicitud de acceso a datos personales, deberá señalar la modalidad en la que prefiere que éstos se reproduzcan, en el caso de solicitudes de rectificación de datos personales, deberá precisar las modificaciones a realizarse y aportar la documentación que sustente su petición; con relación a una solicitud de cancelación, deberá señalar las causas que lo motiven a solicitar la supresión de sus datos personales y, en el caso de la solicitud de oposición, deberá manifestar las causas legítimas o la situación específica que lo llevan a solicitar el cese en el tratamiento, así como el daño o perjuicio que le causaría la persistencia del tratamiento, o en su caso, las finalidades específicas respecto de las cuales requiere ejercer el derecho de oposición.
                    IV) Cualquier otro elemento o documento que facilite la localización de los Datos Personales del Titular.
                    Tratándose de las solicitudes de rectificación de Datos Personales, el Titular respectivo deberá también indicar las modificaciones a realizarse y aportar la documentación que sustente su petición.
                    Para la recepción, registro y atención de las solicitudes para ejercer su derecho de acceso, rectificación, cancelación y oposición a sus Datos Personales, así como para limitar el uso o divulgación de sus datos, y los demás derechos previstos en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares se podrá presentar un escrito debidamente firmado por el titular o su representante legal, directamente en nuestras oficinas o remitir un correo electrónico a la dirección: info@integropay.com (Esta cuenta de correo es solamente para recibir solicitudes de ejercicio de derechos ARCO. Cualquier otro tipo de solicitud no será atendida), precisando que el área legal de “IntegroPAY” será la responsable de atender su solicitud.
                    En caso de que su solicitud no cumpla con alguno de los requisitos antes señalados, se le realizará un requerimiento de información adicional dentro de los cinco días hábiles siguientes a su solicitud, a fin de que subsane las omisiones dentro de un plazo de diez días hábiles contados al día siguiente al de la notificación. Transcurrido el plazo sin desahogar la prevención se tendrá por no presentada la solicitud de ejercicio de los derechos ARCO. En caso de que el Titular atienda el requerimiento de información, el plazo para que el responsable dé respuesta a la solicitud empezará a correr al día siguiente de que el Titular haya atendido el requerimiento
                    Una vez hecho lo anterior el Responsable o sus Encargados le responderán al Titular respectivo en un plazo máximo de veinte días hábiles, contados desde la fecha en que se recibió la solicitud de acceso, rectificación, cancelación u oposición, la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince días siguientes a la fecha en que se le comunica la respuesta al Titular. Tratándose de solicitudes de acceso a Datos Personales, el Responsable o sus Encargados procederán con su entrega previa acreditación de la identidad del solicitante o de su representante legal, según corresponda. Los plazos antes referidos podrán ser ampliados sólo en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.
                    La entrega de los Datos Personales será gratuita, solamente le corresponderá cubrir los gastos justificados de envío o el costo de reproducción en copias u otros formatos. En el caso de que el Titular reitere su solicitud en un periodo menor a doce meses, deberá cubrir los costos correspondientes en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, la Ley Federal de Derechos y demás normatividad aplicable en esta materia que para tales efectos expida el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), a menos que existan modificaciones sustanciales al Aviso de Privacidad que motiven nuevas consultas.
                    Para efectos de las solicitudes de cancelación de Datos Personales, además de lo dispuesto por el presente Aviso de Privacidad, se estará a lo dispuesto en el Artículo 26 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, incluyendo los casos de excepción de cancelación de Datos Personales señalados.
                    La presentación de una solicitud de oposición del uso de Datos Personales por el Titular de los mismos, dará al Responsable la facultad de oponerse al uso de los Datos Personales que como Titular haya entregado al oponente.
            `}
            </p>
            <p>
              {`  
                    QUINTA: TRANSFERENCIAS DE DATOS. Le informamos que en nuestra página de internet y aplicación utilizamos cookies, web beacons y otras tecnologías similares, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
                    `}
            </p>
            <p>
              {`
                    SEXTA: CAMBIOS AL AVISO DE PRIVACIDAD. La RESPONSABLE comunicará el procedimiento y medio por el cual el responsable los cambios al aviso de privacidad a través de la página de internet: www.integropay.com, ya que de conformidad con lo previsto en Ley Federal de Protección de Datos Personales en Posesión de los Particulares el Aviso de Privacidad puede sufrir cambios o actualizaciones derivadas de requerimientos legales, de nuestras propias necesidades por los productos o servicios que ofrecemos, de nuestras prácticas de privacidad, cambios en nuestro modelo de negocio, o por otras causas.
                    Cualquier queja o información adicional respecto al tratamiento de sus datos personales o duda en relación con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares o con su Reglamento, podrá dirigirla al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).
                    `}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacidad;

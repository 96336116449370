import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Privacidad from '../components/Privacidad';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';

const About = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Aviso de Privacidad" />
            <Privacidad/>
            <FooterThree FooterData={FooterData}/>
        </div>
    )
}
export default About;